import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Discount2Up from 'components/Discount2Up';
import DiscountRowSection from 'components/DiscountRowSection';
import useGetDiscountTypeMap from 'hooks/useGetDiscountTypeMap';

export function DiscountBlock({
  component_type: componentType = '',
  discount_type: discountType,
  productDiscountMap,
  ssrStore,
  ...rest
}) {
  const discountTypeMap = useGetDiscountTypeMap({
    propTypeMap: productDiscountMap,
    store: ssrStore,
  });

  const [productDiscounts, setProductDiscounts] = useState([]);

  useEffect(() => {
    if (discountTypeMap) {
      const today = dayjs().get('day');
      const discounts =
        discountType === 'daily'
          ? discountTypeMap[discountType]?.discounts?.filter(
              (discount) => discount.daySchedule[today]
            )
          : discountTypeMap[discountType]?.discounts?.filter(
              (discount) => discount.isActive
            );

      setProductDiscounts(discounts ?? []);
    }
  }, [discountTypeMap, discountType]);

  return productDiscounts?.length > 0 ? (
    componentType === 'two up' ? (
      <Discount2Up discounts={productDiscounts} sectionContent={rest} />
    ) : (
      <DiscountRowSection discounts={productDiscounts} sectionContent={rest} />
    )
  ) : null;
}

export default DiscountBlock;
